<template>
  <div>
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>行业新闻</h1>
      <p>
        智能调度系统统计考核通过对海量的车辆定位数据进行挖掘，并对数据进行统计分类，自动生成各类营运及考核报表，为管理提供决策支持。
        根据车辆运营情况对车辆、班次、公里、准点率等信息进行统计，同时对大间隔、串车、正点运行、线路时间等参数进行考核设置，系统根据设定的标准自动生成考核报表。
      </p>
      <!-- <h2>01</h2> -->
      <h4>1、首末班准点考核</h4>
      <p>
        通过定位数据对公交线路进行准点率及运行稳定性进行分析，按照线路、车队、分公司逐级生成日报表、月报表及年报表，通过对首末班准点运营情况的分析，有助于提高运营计划编制水平，实现运营计划与实际运营调度的高度吻合。
      </p>
      <!-- <h2>02</h2> -->
      <h4>2、运营公里统计考核</h4>
      <p>
        针对车辆、线路及分公司核算月、季、年营业里程汇总，并对营运计划班次进行统计考核，包括早晚高峰、平峰的数据分析，同时运营公里与公交车辆班次、油耗等相结合，为有效分析车辆运营成本提供数据支持。
      </p>
      <!-- <h2>03</h2> -->
      <h4>3、线路运行正点率统计考核</h4>
      <p>
        车辆运行正点率是乘客关注的重点，通过对车队、线路及分公司正点率统计考核可以提升车辆准时发车、准点到站，为乘客提供安全、便捷、准点的出行服务。
      </p>
      <!-- <h2>04</h2> -->
      <h4>4、大间隔统计考核</h4>
      <p>
        通过定位数据对车辆、站点进行大间隔统计，包括大间隔时间、地点、车号、驾驶员等信息，同时按照车队、分公司、总公司逐级统计，为调度决策及营运措施提供数据支持。
      </p>
      <!-- <h2>05</h2> -->
      <h4>5、串车统计考核</h4>
      <p>
        通过对车辆实时定位，对串车进行明细统计，包括串车时间、串车车辆数、车号、站点等信息。同时按照线路、车队、公司自动生成考核报表。
      </p>
      <p>
        考核目标是提升乘客服务，济南公交以精准的考核数据为依托，狠抓营运服务质量，以润物细无声的方式提升乘客服务水平，为市民提供良好的乘车体验。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
.info-content {
  margin: 82px 160px 60px;
}
h2 {
  font-size: 30px;
  line-height: 50px;
  color: #111;
  text-align: center;
  margin: 15px auto;
}
h4 {
  font-size: 25px;
  line-height: 80px;
  color: #333;
  margin: 15px 0;
}
p {
  font-size: 18px;
  line-height: 50px;
  text-indent: 2em;
  color: #333;
  margin: 10px 0;
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro {
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
  text-indent: 2em;
}
</style>
